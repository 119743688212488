import React from 'react'
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import ContactBar from '../../components/ContactBar';
import SingleImage from '../../library/SingleImage';
import Layout from '../../components/Layout';

const KraeuterPage = (props) => (
  <Layout location={props.location}>
    <div className="page offer">
      <Helmet>
        <title>Angebot, Behandlungsmethoden</title>
        <meta name="description" content="Akupunktur, Kräutermedizin, Moxa, Schröpfen, Mein Angebot, Behandlungsmethoden" />
        <meta property="og:title" content="Angebot, Behandlungsmethoden" />
        <meta property="og:description" content="Akupunktur, Kräutermedizin, Moxa, Schröpfen, Mein Angebot, Behandlungsmethoden" />
        {/* <meta name="keywords" content="KEYWORDs§" /> */}
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <h1>Kräutermedizin</h1>
        <SingleImage image={props.data.herbs.fluid} />
        <p>Schon vor mehr als 4000 Jahren entdeckte man die Heilkraft die aus der Natur entsteht. Man sammelte Pflanzen, Rinden und Wurzeln kochte sie aus, zermahlte sie oder röstete sie über dem Feuer. Die zahlreichen Erkenntnisse, welche man aus der Verwendung chinesischer Kräuter gewann, schrieb man auf und überlieferte sie bis zum heutigen Tag. Heute können wir auf eine langjährige Erfahrung und Weiterentwicklung der Kräutermedizin zurückblicken. Die Kräuterrezepturen sind heutzutage als Pulver, Tropfen oder fertige Pillen erhältlich, auf Wunsch kann man jedoch immer noch die Rohdroge beziehen und den Dekokt selber herstellen.</p>
        <p>Wird meist in Kombination mit Akupunktur angewendet, geeignet für Kinderwunsch, Burn out, Depression, Allergien, chronische Verdauungsbeschwerden, systemische Erkrankungen...</p>
      </div>
    </div>
  </Layout>
)

export default KraeuterPage;


export const query = graphql`
  query HerbsImageQuery {
    herbs: imageSharp(fluid: { originalName: {regex: "/kraeuter/" }}) {
      fluid(maxHeight: 580, cropFocus: ENTROPY ) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

